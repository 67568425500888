button:focus {
  border: none;
  outline: none;
}

.lab-btn-inverse {
  display: inline-block;
  line-height: 56px;
  padding: 0 30px;
  background: #df01ae;
  font-weight: 700;
  color: #fff;
  font-family: "Arvo", serif;
  font-style: italic;
  font-size: 14px;
  text-transform: capitalize;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 4px 4px 0 0 currentColor;
}

.lab-btn-inverse:hover,
.lab-btn-inverse:active,
.lab-btn-inverse:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.template-by:hover {
  text-decoration: underline;
}

.email-link,
.phone-link,
.footer-item-link {
  color: #df01ae;
}

.email-link:hover,
.phone-link:hover,
.footer-item-link:hover {
  text-decoration: underline;
}

.custom-footer-top-item {
  padding: 10px 10px !important;
}

.custom-footer-top-item .lab-inner {
  justify-content: center !important;
}

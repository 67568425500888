$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


// Colors

$title-color: #182d41;
$desc-color: #555555;
$black-color:#000000;
$theme-color: #df01ae;
$primary-color: #00102e;
$border-color: #f0f0f0;
$white-color: #fff;
$ash-color: #bdbdbd;
$bg-dark: #0f1b28;
$bg-color:#FFFBF9;
$body-color:#00163f;
$gradient-color:linear-gradient(to top, #8dc53f, #76c14e, #5ebd5b, #46b868, #29b373);

// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$dribble: #ea4c89;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$plaseholder: #a0a6bc;
$pinterest : #bd081c;
$basketball : #e94c89;
$vimeo : #3b5998;
//== Typography


//Google fonts
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// font-family
$font-family1: 'Poppins',
sans-serif;
//$font-family1: 'Roboto', sans-serif;
$font-family2: 'Arvo',
serif;

// font-size
$fs-base: 16px !default;
$fs-h1: 70px;
$fs-h2: 48px;
$fs-h3: 35px;
$fs-h4: 30px;
$fs-h5: 24px;
$fs-h6: 20px;
$fs-h7: 18px;

// Description line height
$lh-p: 28px;

//  transition
$transition: all 0.3s ease;
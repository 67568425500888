/* @import url("../../assets/css/fontawesome.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i"); */
/*
	Read Only by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
/* Header */

#header {
  background: #fff;
  color: #df01ae;
  height: 100%;
  position: relative;
  text-align: center;
  top: 0;
  overflow-y: scroll;
  width: 23em;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 4px 4px 0 0 currentColor;
}
.doc-content-wrapper {
  padding-top: 30px;
}
.doc-content-wrapper a {
  color: #df01ae;
}
.doc-content-wrapper a:hover {
  text-decoration: underline;
}
.doc-content-wrapper p {
  font-size: 20px;
  margin-top: 20px;
}
#header {
  list-style: none;
  margin: 0;
  padding: 0;
}
#header li {
  display: block;
  padding: 0;
  cursor: pointer;
}
#header li button {
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  text-align: left;
}
#header li {
  font-family: "montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  -moz-transition: none;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
  border: 0;
  text-align: left;
  color: #00163e !important;
  display: block;
  padding: 7px 12px;
  text-decoration: none;
}
#header li.active {
  background: linear-gradient(45deg, #8769a9, #507cb3);
  color: #fff !important;
}

#header li:hover {
  background: linear-gradient(45deg, #8769a9, #507cb3);
  color: #fff !important;
}
#header li:first-child {
  margin-top: 0px;
}
/* Wrapper */

#wrapper {
  background: rgba(0, 22, 63, 0.3);
}

@media screen and (max-width: 1680px) {
  /* Header */
  #header {
    width: 100%;
  }
  #header > header {
    background: #191919;
    padding: 30px 20px 10px;
  }
}

/* Large */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  #header > footer {
    bottom: auto;
    left: auto;
    margin: 1em 0 0 0;
    position: relative;
    right: auto;
    top: auto;
  }
  /* Wrapper */

  #wrapper {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    padding: 44px 0 1px 0;
  }
  /* .next-prev-wrapper {
    display: block !important;
    text-align: center;
  }
  .next-prev-wrapper div{
    padding-top: 20px;
  } */
}

/* Small */

@media screen and (max-width: 767px) {
  .next-prev-wrapper {
    display: block !important;
    text-align: center;
  }
  .next-prev-wrapper div {
    padding-top: 20px;
  }
  .floating-content-wrapper {
    width: 320px !important;
  }
}

/* XSmall */

@media screen and (max-width: 480px) {
  .next-prev-wrapper {
    display: block !important;
    text-align: center;
  }
  .next-prev-wrapper div {
    padding-top: 20px;
  }
  ul.actions {
    margin: 0 0 2.25em 0;
  }
  ul.actions li {
    display: block;
    padding: 1.125em 0 0 0;
    text-align: center;
    width: 100%;
  }
  ul.actions li:first-child {
    padding-top: 0;
  }
  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }
  ul.actions li > *.icon:before {
    margin-left: -2em;
  }
  ul.actions.small li {
    padding: 0.5625em 0 0 0;
  }
  ul.actions.small li:first-child {
    padding-top: 0;
  }
  ul.feature-icons li {
    display: block;
    width: 100%;
  }
}
/* Styles the thumbnail */

/* Floating Button CSS */

.floating-content-wrapper {
  width: 400px;
  height: 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 12px 45px rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin: 0 0 10px 0;
  overflow: hidden;
  opacity: 0;
}
.floating-content-wrapper.open {
  height: 400px;
  opacity: 1;
}
.floating-content-wrapper.popup-ani {
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.floating-content-wrapper h1 {
  font-size: 20px;
  color: #757575;
  padding: 25px 0;
  margin: 0;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.floating-content-wrapper .love {
  width: 20px;
  height: 20px;
  background-position: 35px 84px;
  display: inline-block;
  margin: 0 6px;
  background-size: 62px;
}
.floating-content-wrapper .ba-logo {
  width: 65px;
  height: 25px;
  background-position: 0px;
  margin: 0 auto;
  opacity: 0.5;
  cursor: pointer;
}
.floating-content-wrapper .ba-logo:hover {
  opacity: 1;
}
.logo-ani {
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
}
.floating-btn-wrapper {
  width: 65px;
  height: 65px;
  background: linear-gradient(45deg, #8769a9, #507cb3);
  border-radius: 50% 50%;
  float: right;
  border: 5px solid #b2bedc;
  z-index: 5;
  position: relative;
  cursor: pointer;
}
.floating-btn-wrapper .wrap {
  transform: rotate(0deg);
  -webkit-transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
  transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
}
.floating-btn-wrapper .ani {
  transform: rotate(45deg);
  -webkit-transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
  transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
}
.floating-btn-wrapper .close {
  background-position: -2px 1px;
  transform: rotate(-45deg);
  float: none;
  /*Bootstrap Overide*/
  opacity: 1;
  /*Bootstrap Overide*/
}
.floating-section-wrapper {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 1000;
}
.ba-settings {
  position: absolute;
  top: -25px;
  right: 0px;
  padding: 10px 20px;
  background-color: #555;
  border-radius: 5px;
  color: #fff;
}

/* Youtube Embed CSS */

.youtube-embed-wrapper {
  height: 600px;
}

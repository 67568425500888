/*================*** Header Section Start Here     ***===================*/

.header-section {
    background: rgba($body-color, $alpha: .30);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    @extend %abs;
    @extend %w-100;
    z-index: 999;

    &.header-fixed {
        position: fixed;
        @extend %w-100;
        top: 0;
        left: 0;
        animation: fadeInDown .5s;
        -webkit-animation: fadeInDown .5s;
        -moz-animation: fadeInDown .5s;
        box-shadow: 0px 4px 20px 0px rgba($theme-color, $alpha: .20);
        border-bottom: 1px solid rgba($theme-color, $alpha: .10);
        z-index: 999;

        .header-bottom {
            background: rgba($body-color, $alpha:1);
            backdrop-filter: blur(20.0px);
            -webkit-backdrop-filter: blur(20.0px);
        }
    }

}

.header-wrapper {
    padding: 23px 0;
    @extend %flex;
    @extend %justify-between;
    @extend %align-items-center;

    @include breakpoint(sm) {
        position: relative;
    }

    .logo {
        a {
            font-size: 24px;
            font-weight: 600;
            color: $white-color;
            display: inline-block;
        }
    }

    .menu-area {
        @extend %flex;
        @extend %align-items-center;

        .cart-ticket {
            @include breakpoint(max-sm) {
                display: none;
            }

            @extend %flex;
            @extend %align-items-center;
            column-gap: 35px;

            .cart-icon {
                @extend %rel;

                a {
                    @extend %rel;
                    color: $white-color;

                    i {
                        font-size: 24px;
                    }

                    span {
                        @extend %abs;
                        @extend %bg-white;
                        @extend %d-inline-block;
                        @extend %text-bold;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        @extend %text-center;
                        font-size: 14px;
                        font-family: $font-family2;
                        border-radius: 50%;
                        color: $theme-color;
                        top: -8px;
                        right: -8px;
                    }
                }

                .cart-content {
                    @extend %abs;
                    right: 0;
                    top: 60px;
                    width: 280px;
                    background: $body-color;
                    @extend %p-15;
                    border-radius: 4px;
                    box-shadow: 0 0 10px 2px rgba($theme-color, $alpha: .2);
                    transform: translateX(-30px);
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;

                    .cart-list {
                        .cart-item {
                            @extend %mb-10;
                            background: rgba($theme-color, $alpha: .60);

                            &:last-child {
                                @extend %mb-0;
                            }

                            .cart-inner {
                                @extend %flex;
                                @extend %align-items-center;
                                @extend %w-100;

                                .cart-thumb {
                                    width: 60px;
                                    height: 70px;

                                    img {
                                        @extend %w-100;
                                        @extend %h-100;
                                    }
                                }

                                .cart-details {
                                    width: calc(100% - 60px);
                                    @extend %p-10;
                                    @extend %rel;

                                    h6 {
                                        @extend %mb-5;

                                        a {
                                            @extend %text-clamp-1;
                                            font-size: 16px;
                                        }
                                    }

                                    p {
                                        font-size: 14px;
                                        @extend %mb-0;
                                        @extend %flex;
                                        @extend %justify-between;
                                    }

                                    .close-btn {
                                        @extend %abs;
                                        top: -5px;
                                        right: -5px;

                                        a {
                                            background: rgba($white-color, $alpha: .60);
                                            color: $desc-color;
                                            width: 18px;
                                            height: 18px;
                                            line-height: 18px;
                                            @extend %text-center;
                                            border-radius: 50%;
                                            @include transition($transition);

                                            i {
                                                font-size: 14px;
                                            }

                                            &:hover {
                                                background: rgb(231, 2, 59);
                                                color: $white-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .lab-btn {
                        @extend %mt-20;
                        color: $theme-color;
                        line-height: 40px;
                        padding: 0 20px;
                    }
                }

                &:hover {
                    .cart-content {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }
                }
            }

            .ticket-btn {}
        }
    }
}

.menu {
    @extend %flex;

    @include breakpoint(lg) {
        margin-right: 30px;
    }

    >li {
        >a {
            color: $white-color;
            font-size: $fs-base;
            font-weight: 700;
            padding: 15px 22px;
            text-transform: capitalize;

            i {
                margin-right: 5px;
            }
        }
    }

    @include breakpoint(lg) {
        li {
            position: relative;

            .submenu {
                position: absolute;
                top: calc(100%);
                left: 0;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
                z-index: 9;
                background: #ffffff;
                width: 220px;
                padding: 0;
                -webkit-transform: translateY(35px);
                -ms-transform: translateY(35px);
                transform: translateY(35px);
                opacity: 0;
                visibility: hidden;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);


                li {
                    padding: 0;

                    a {
                        padding: 10px 20px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: $title-color;
                        font-weight: 500;
                        border-bottom: 1px solid $border-color;

                        &:hover,
                        &.active {
                            background: $theme-color;
                            padding-left: 22px;
                            color: $white-color;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }

                    .submenu {
                        left: 100%;
                        top: 0;
                    }
                }
            }

            &:hover {
                >.submenu {
                    -webkit-transform: translateY(15px);
                    -ms-transform: translateY(15px);
                    transform: translateY(15px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        @include breakpoint(max-xl) {
            li {
                a {
                    padding: 10px 12px;
                }
            }
        }
    }

    @include breakpoint(max-lg) {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: auto;
        max-height: 400px;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
        -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
        transform: scaleY(0);
        transform-origin: top;

        //glassmorphism
        background: rgba($primary-color, $alpha: .95);

        li {
            width: 100%;
            padding: 0;

            a {
                display: block;
                padding: 10px 25px;
                font-size: 15px;
                font-weight: 700;
                text-transform: capitalize;
                border-top: 1px solid rgba($white-color, $alpha: .10);
                color: $white-color;

                &:hover {
                    background: rgba($theme-color, $alpha: .60);
                }
            }

            .submenu {
                padding-left: 20px;
                display: none;

                li {
                    width: 100%;

                    a {
                        font-size: 14px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

        &.active {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
}

.header-bar {
    @extend %rel;
    @extend %pointer;
    width: 25px;
    height: 20px;
    @extend %ml-30;

    span {
        @extend %abs;
        @extend %d-inline-block;
        @extend %w-100;
        height: 3px;
        @include transition($transition);
        background-color: $white-color;
        left: 0;

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            top: 52%;
            transform: translateY(-65%);
        }

        &:last-child {
            bottom: 0;
        }
    }

    &.active {
        span {
            &:first-child {
                @include transform(rotate(45deg) translate(3px, 9px));
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                @include transform(rotate(-45deg)translate(3px, -9px));
            }
        }
    }

    @include breakpoint(max-sm) {
        width: 15px;
        height: 16px;
        margin-right: 20px;

        span {
            height: 2px;
            width: 20px;
        }

        &.active {
            span {
                &:first-child {
                    @include transform(rotate(45deg) translate(4px, 6px));
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    @include transform(rotate(-45deg)translate(4px, -6px));
                }
            }
        }
    }
}

.menu-item-has-children {


    >a {
        @extend %rel;

        &::after {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 25px;
            width: 10px;
            height: 2px;
            background-color: $white-color;
        }

        &::before {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 29px;
            width: 2px;
            height: 10px;
            background-color: $white-color;
            @include transition($transition);
        }

        @include breakpoint(lg) {
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 10px;
                height: 2px;
                background-color: $white-color;
            }

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 4px;
                width: 2px;
                height: 10px;
                background-color: $white-color;
                @include transition($transition);
            }
        }


    }

    &:hover {
        >a {

            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }

    &.open {
        >a {

            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }
}

/*================*** Header Section End Here     ***===================*/